// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-image {
    & {
        height: remify(400, 16);
    }
}

.swiper-caption {
    & {
        left: 50%;
        right: auto;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.swiper-title {
    & {
        font-size: remify(40, 16);
        max-width: 100%;
        padding: remify(15 50, 40);
    }
}
