// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-image {
    & {
        height: remify(600, 16);
    }
}
