// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #1C76BD;
$primary_alt: #0C5788;

$accent: #1C76BD;
$accent_alt: #4791D7;

$light: #FFFFFF;
$light_alt: #EEEEEE;

$dark: #000000;
$dark_alt: #111111;

$foreground: #000000;
$foreground_alt: #111111;

$background: #FFFFFF;
$background_alt: #EEEEEE;

$page_background: #0C5788;
$page_background_alt: #1C76BD;

$warning: #F83636;
$warning_alt: #F76868;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Walkway", "Open Sans", "Arial", "Helvetica", sans-serif;
$body-font: "Open Sans", "Arial", "Helvetica", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1500;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxl-break: 1280em / 16;
$xl-break: 1024em / 16;
$l-break: 960em / 16;
$m-break: 853em / 16;
$s-break: 768em / 16;
$xs-break: 640em / 16;
$xxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    toolbar: 6,
    header: 5,
    navigation: 4,
    hero: 2,
    content: 3,
    footer: 1,
);
