// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    &.-fullbleed {
        padding: remify(30);
    }
}

.content_inner {
    & {
        padding: remify(30);
    }
}

.content_sidebar {
    & {
        width: remify(360, 16);
    }
}
