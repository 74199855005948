// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $xxl-break) {
        font-size: 1.25vw;
    }
}

.header_logo {
    & {
        margin: 0;
        max-width: remify(330, 16);
    }
}

.header_menu-list_container {
    @media screen and (max-width: $xxl-break) {
        .menu-list.-navigation {
            font-size: remify(15, 16);
        }
    }
}
