// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    &.-fullbleed {
        padding-bottom: remify(30);
        padding-top: remify(30);
    }
}

.footer_inner {
    & {
        padding-bottom: remify(30);
        padding-top: remify(30);
    }
}

.footer_row {
    .col {
        padding: remify(25, 16) 3.25%;
    }
    
    .col:not(:last-child) {
        border-right: remify(1) solid lighten(desaturate(adjust-hue($primary_alt, -0.1483), 29.4691), 9.7608);
    }
}

.footer_title {
    & {
        font-size: remify(18, 16);
        text-align: left;
    }

    &.-alt {
        font-size: remify(30, 16);
    }
}

.footer_text {
    & {
        font-size: remify(14, 16);
        text-align: left;
    }
}

.footer_logo {
    & {
        margin: remify(0 0 35, 16);
        max-width: remify(310, 16);
    }
}
