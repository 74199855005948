// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Quick Quote
\* ------------------------------------------------------------------------ */

.quick-quote_title {
    & {
        color: $light;
        font-size: remify(25.73, 16);
    }
}

.quick-quote_content .nf-form-cont {
    .button.-submit {
        font-size: remify(17.69, 16);
    }
}

// fold variant

.quick-quote.-fold {
    & {
        margin-top: calc(#{remify(-30)} + #{remify(-30, 16)});
        position: relative;
    }

    &:before {
        border-right: remify(20, 16) solid $primary;
        border-top: remify(30, 16) solid transparent;
        content: "\0020";
        display: block;
        height: 0;
        position: absolute;
        right: 100%;
        top: 0;
        width: 0;
        z-index: -1;
    }
}
